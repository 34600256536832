<template>
  <div id="addPassagewayDialog">
    <el-dialog
      title="新建停车场"
      :visible.sync="show"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @closed="reset"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addForm"
        label-width="100px"
      >
        <el-form-item label="停车场名称" prop="parkingLotName">
          <el-input
            v-model="addForm.parkingLotName"
            placeholder="请输入停车场名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="停车场类型" prop="parkingLotType">
          <el-select
            v-model="addForm.parkingLotType"
            placeholder="请选择停车场类型"
          >
            <el-option label="地下停车场" value="地下停车场"></el-option>
            <el-option label="地面停车场" value="地面停车场"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="主体类型" prop="subjectType">
          <el-select v-model="addForm.subjectType" placeholder="请选择主体类型">
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="(item, index) of select_subjectType"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商圈" prop="businessCircleId">
          <el-select
            v-model="addForm.businessCircleId"
            placeholder="请选择商圈"
          >
            <el-option
              v-for="item in businessList"
              :key="item.businessCircleId"
              :label="item.businessCircleName"
              :value="item.businessCircleId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="父级停车场">
          <el-select
            v-model="addForm.parkingParentId"
            placeholder="请选择父级停车场"
            clearable
          >
            <el-option
              v-for="item in parkingLotList"
              :key="item.parkingLotId"
              :label="item.parkingLotName"
              :value="item.parkingLotId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="开票税点"
          prop="taxRate"
          v-if="!addForm.parkingParentId"
        >
          <el-select v-model="addForm.taxRate" placeholder="请选择开票税点">
            <el-option
              v-for="item in select_taxRate"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联合停车场" v-if="!addForm.parkingParentId">
          <el-select
            v-model="addForm.parkingGroupId"
            placeholder="请选择联合停车场"
            clearable
          >
            <el-option
              v-for="item in parkingGroupList"
              :key="item.parkingGroupId"
              :label="item.groupName"
              :value="item.parkingGroupId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="商户号"
          prop="shopId"
          v-if="!addForm.parkingParentId"
        >
          <el-input
            v-model="addForm.shopId"
            placeholder="请输入商户号"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="门店号"
          prop="staffId"
          v-if="!addForm.parkingParentId"
        >
          <el-input
            v-model="addForm.staffId"
            placeholder="请输入门店号"
          ></el-input>
        </el-form-item>
        <el-form-item label="手续费" v-if="!addForm.parkingParentId">
          <el-input
            v-model="addForm.interestRate"
            placeholder="请输入手续费"
          ></el-input>
        </el-form-item>
        <el-form-item label="总停车位">
          <el-input
            v-model.number="addForm.totalLots"
            placeholder="请输入总停车位"
          ></el-input>
        </el-form-item>
        <el-form-item label="人防车位">
          <el-input
            v-model.number="addForm.civilDefense"
            placeholder="请输入人防车位"
          ></el-input>
        </el-form-item>
        <el-form-item label="产权车位">
          <el-input
            v-model.number="addForm.property"
            placeholder="请输入产权车位"
          ></el-input>
        </el-form-item>
        <gaode-map @mapClick="mapClick" :height="'300px'" />
        <el-form-item></el-form-item>
        <el-form-item label="停车场地址">
          <el-input
            v-model="addForm.parkingLotAddress"
            placeholder="请输入停车场地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="关联停车场">
          <el-button
            class="addCorrelationBtn"
            @click="correlationDialog.show = true"
            ><i class="iconfont icon-jiahao"></i> 添加
          </el-button>
        </el-form-item>
        <div class="table-container">
          <el-table :data="addForm.list">
            <el-table-column
              prop="parkingLotCode"
              label="停车场编号"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="serverIp" label="服务器IP" align="center">
            </el-table-column>
            <el-table-column prop="address" label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="delCorrelation(scope.row)"
                  >删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      class="correlationDialog"
      title="新增关联停车场"
      :visible.sync="correlationDialog.show"
      :close-on-press-escape="false"
      @closed="correlationDialog_reset"
    >
      <el-form
        :model="correlationDialog"
        :rules="correlationDialog.rules"
        ref="correlationDialog"
        label-width="100px"
      >
        <el-form-item label="停车场编号" prop="parkingLotCode">
          <el-input
            v-model="correlationDialog.parkingLotCode"
            placeholder="请输入停车场编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="服务器" prop="server">
          <el-select
            v-model="correlationDialog.server"
            placeholder="请选择服务器"
          >
            <el-option
              v-for="item of serverList"
              :key="item.serverId"
              value-key="serverId"
              :label="item.serverName"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="correlationDialog.show = false"
          >取 消</el-button
        >
        <el-button type="primary" size="medium" @click="correlationSave"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {
    gaodeMap: () => import("@/components/gaode-map.vue"),
  },
  props: ["getList", "businessList"],
  data() {
    return {
      show: false,
      loading: false,
      serverList: [],
      parkingGroupList: [],
      parkingLotList: [],
      addForm: {
        //停车场名称
        parkingLotName: "",
        //停车场类型
        parkingLotType: "",
        //主体类型
        subjectType: "",
        //商圈
        businessCircleId: "",
        //父级停车场
        parkingParentId: "",
        //开票税点
        taxRate: "",
        //联合停车场
        parkingGroupId: "",
        //商户号
        shopId: "",
        //门店号
        staffId: "",
        //手续费
        interestRate: "",
        //经度
        parkingLotLng: "",
        //纬度
        parkingLotLat: "",
        //停车场地址
        parkingLotAddress: "",
        //关联停车场列表
        list: [],
        //总停车位
        totalLots: "",
        //人防车位
        civilDefense: "",
        //产权车位
        property: "",
      },
      marker: "",
      addFormRules: {
        parkingLotName: [
          { required: true, message: "请输入停车场名称", trigger: "blur" },
        ],
        parkingLotType: [
          { required: true, message: "请选择停车场类型", trigger: "change" },
        ],
        subjectType: [
          { required: true, message: "请选择主体类型", trigger: "change" },
        ],
        businessCircleId: [
          { required: true, message: "请选择商圈", trigger: "change" },
        ],
        taxRate: [
          { required: true, message: "请选择开票税点", trigger: "change" },
        ],
        shopId: [{ validator: this.validShopId, trigger: "blur" }],
        staffId: [{ validator: this.validStaffId, trigger: "blur" }],
      },
      correlationDialog: {
        show: false,
        parkingLotCode: "",
        server: "",
        rules: {
          parkingLotCode: [
            { required: true, message: "请输入停车场编号", trigger: "blur" },
          ],
          server: [
            { required: true, message: "请选择服务器", trigger: "change" },
          ],
        },
      },
    };
  },
  created() {
    this.getParkingList();
    this.getServeList();
    this.getParkingGroup();
  },
  methods: {
    async getParkingList() {
      try {
        let res = await this.$http.post("/parking/listAll", {
          containChild: "否",
        });
        if (res.code === 0) {
          this.parkingLotList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    validShopId(rule, value, callback) {
      let reg = /^333021273990036\d{3}$/;
      if (!value) {
        callback();
      } else if (!reg.test(value)) {
        callback(new Error("商户号必须是: 333021273990036XXX 组合"));
      } else {
        callback();
      }
    },
    validStaffId(rule, value, callback) {
      let reg = /^\d{4}$/;
      if (!value) {
        callback();
      } else if (!reg.test(value)) {
        callback(new Error("门店号必须是由4位数字组合 (例如：0001)"));
      } else {
        callback();
      }
    },
    async getServeList() {
      try {
        let res = await this.$http.get("/server/list/all");
        if (res.code === 0) {
          for (let item of res.data) {
            item.serverName = `${item.serverName}（${item.serverIp}）`;
          }
          this.serverList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    async getParkingGroup() {
      try {
        let res = await this.$http.get("/parking/group/list/all");
        if (res.code === 0) {
          this.parkingGroupList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    delCorrelation(row) {
      this.addForm.list.forEach((item, index) => {
        if (
          item.parkingLotCode === row.parkingLotCode &&
          item.serverId === row.serverId
        ) {
          this.addForm.list.splice(index, 1);
        }
      });
    },
    mapClick(params) {
      Object.assign(this.addForm, {
        parkingLotAddress: params.address,
        parkingLotLng: params.longitude,
        parkingLotLat: params.latitude,
      });
    },
    reset() {
      this.$set(this, "addForm", {
        parkingLotName: "",
        parkingLotType: "",
        subjectType: "",
        businessCircleId: "",
        parkingParentId: "",
        taxRate: "",
        parkingGroupId: "",
        shopId: "",
        staffId: "",
        interestRate: "",
        parkingLotLng: "",
        parkingLotLat: "",
        parkingLotAddress: "",
        list: [],
        totalLots: "",
        civilDefense: "",
        property: "",
      });
      this.$refs["addForm"].resetFields();
    },
    correlationDialog_reset() {
      this.correlationDialog.parkingLotCode = "";
      this.correlationDialog.server = "";
      this.$refs["correlationDialog"].resetFields();
    },
    correlationSave() {
      let falg = true;
      this.$refs["correlationDialog"].validate((valid) => {
        if (!valid) {
          falg = false;
        }
      });
      if (!falg) {
        return;
      }
      let isAdd = true;
      for (let i = 0, len = this.addForm.list.length; i < len; i++) {
        let item = this.addForm.list[i];
        if (
          item.parkingLotCode === this.correlationDialog.parkingLotCode &&
          item.serverId === this.correlationDialog.server.serverId
        ) {
          isAdd = false;
          this.$message.error("该条记录已关联");
          break;
        }
      }
      if (isAdd) {
        this.addForm.list.push({
          parkingLotCode: this.correlationDialog.parkingLotCode,
          serverIp: this.correlationDialog.server.serverIp,
          serverId: this.correlationDialog.server.serverId,
        });
        this.correlationDialog.show = false;
      }
    },
    async confirmBtn() {
      let falg = true;
      this.$refs["addForm"].validate((valid) => {
        if (!valid) {
          falg = false;
        }
      });
      if (!falg) {
        return;
      }
      if (!this.addForm.parkingLotLng || !this.addForm.parkingLotLat) {
        this.$message.warning("请在地图上标出坐标");
        return;
      }
      if (this.addForm.list.length <= 0) {
        this.$message.warning("至少关联一个停车场");
        return;
      }
      let list = this.addForm.list.map((item) => {
        return {
          parkingLotCode: item.parkingLotCode,
          serverId: item.serverId,
        };
      });
      if (this.addForm.parkingParentId) {
        this.addForm.taxRate = null;
        this.addForm.parkingGroupId = null;
        this.addForm.shopId = null;
        this.addForm.staffId = null;
        this.addForm.interestRate = null;
      }
      try {
        this.loading = true;
        let res = await this.$http.post("/parking/add", {
          ...this.addForm,
          list,
        });
        if (res.code === 0) {
          this.getList();
          this.$message.success("添加成功");
          this.show = false;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#addPassagewayDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 500px;

      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);

        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;

          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }

        .el-dialog__headerbtn {
          position: initial;

          i {
            color: black;
          }
        }
      }

      .el-dialog__body {
        padding: 15px;

        .el-select {
          width: 100%;
        }

        .table-container {
          .el-table
            .el-table__body-wrapper
            .el-table__body
            td.el-table__cell
            .cell {
            color: black;
          }
        }

        .addCorrelationBtn {
          border-color: $main-color;
          border-style: dashed;
          color: $main-color;

          i {
            font-size: 14px;
          }
        }
      }

      .el-dialog__footer {
        text-align: center;

        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }

  .correlationDialog {
    .el-dialog__wrapper {
      .el-dialog {
        width: 300px;
      }
    }
  }
}
</style>
